import axios from 'axios';
import { Message, Loading } from 'element-ui';
import configs from './config';
import { resetVuex } from './util';
import router from '../router';
import api from './api';
import { Promise } from 'core-js';
import vx from '../store';
import cookies from 'vue-cookies';
let qs = require('qs');
let path = null;
let loadingFrequency = 0; //loading次数
let unwantedLoading = [
  api.user.orderFind,
  api.homeIndex.readMsg,
  api.finance.getStatus,
  api.sourceCenter.ImagesGroupEdit,
  api.sourceCenter.ImagesGroupAdd,
  api.sourceCenter.ImagesGroupDel,
  api.sourceCenter.ImagesGroupSort,
  api.shopDiy.getOrderStatus,
  api.cashier.Buycreate,
  api.set.buyGorH.getOrderStatus,
  api.admin.upRenew.orderFind,
]; //不需要加载loading的接口
//开发环境
if (process.env.NODE_ENV == 'development') {
  path = '/admin';
}
//生产环境
if (process.env.NODE_ENV == 'production') {
  path = configs.baseurl + '/admin';
}
axios.defaults.timeout = 30000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//设置拦截器
axios.interceptors.request.use(
  config => {
    if (config.url === api.set.Wxticket || config.url === api.set.BaiduCloudDisk.handleAuthorize || config.url === api.serviceProvider.handleAuthorize) {
      path = configs.baseurl;
    }
    if (unwantedLoading.indexOf(config.url) == -1) startLoading();
    if (config.url.indexOf('/Cashier/') == 0) {
      let token = cookies.get('c_token');
      if (token) config.headers['access-token'] = token;
      config.url = path.slice(0, -6) + config.url;
    } else {
      let token = cookies.get('token');
      if (token) config.headers['access-token'] = token;
      config.url = path + config.url;
    }
    loadingFrequency++;
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);
//响应拦截器
axios.interceptors.response.use(
  response => {
    loadingFrequency--;
    if (!loadingFrequency) endLoading();
    if (response.data.code == 10005) {
      //token失效过期
      resetVuex();
      cookies.remove('token');
      Message.error('登录已失效');
      router.push('/login').catch(err => err);
    } else if (response.data.code == 90002) {
      //站点失效
      Message.error('站点已失效');
      router.push('/shop').catch(err => err);
    }
    return response.data;
  },
  err => {
    loadingFrequency--;
    if (!loadingFrequency) endLoading();
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误';
          break;

        case 401:
          err.message = '未授权，请登录';
          break;

        case 403:
          err.message = '拒绝访问';
          break;

        case 404:
          err.message = '请求地址出错';
          break;

        case 408:
          err.message = '请求超时';
          break;

        case 500:
          err.message = '服务器内部错误';
          break;

        case 501:
          err.message = '服务未实现';
          break;

        case 502:
          err.message = '网关错误';
          break;

        case 503:
          err.message = '服务不可用';
          break;

        case 504:
          err.message = '网关超时';
          break;
        case 505:
          err.message = 'HTTP版本不受支持';
          break;
        default:
      }
    }
    Message.error(err.message);
    return Promise.reject(err);
  }
);
function startLoading() {
  // loading = Loading.service({
  //   lock: true,
  //   text: '加载中……',
  //   background: 'rgba(0, 0, 0, 0.7)'
  // })

  vx._mutations['user/changeLoadingFlag'][0](true);
}
function endLoading() {
  // loading.close()
  vx._mutations['user/changeLoadingFlag'][0](false);
}
//get请求
function get(url, data = {}) {
  let params = {
    params: data,
  };
  return new Promise((resolve, reject) => {
    axios
      .get(url, params)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
//post请求
function post(url, data = {}, iscashier = 0) {
  return new Promise((resolve, reject) => {
    let interface_data = data;
    if (url === api.shortVideo.shortVideoAdd || url === api.shortVideo.shortVideoEdit) {
      axios.defaults.headers['Content-Type'] = 'application/json';
    } else {
      axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      interface_data = qs.stringify(data);
    }
    axios
      .post(url, interface_data)
      .then(res => {
        resolve(res);
        addLog(url, data);
      })
      .catch(err => {
        reject(err);
      });
  });
}
function addLog(url, data = {}) {
  let params = {
    request_api: url,
  };
  params.u_account = url == '/users/login' || url == '/users/codeLogin' ? data.u_account : localStorage.getItem('u_account');
  let uniacid = url == '/Account/innerProgram' ? data.uniacid : localStorage.getItem('u_id');
  if (uniacid) params.uniacid = uniacid;
  axios.post('/AdminOperationLog/add', qs.stringify(params));
}
function uploadFile(url, data = {}, onUploadProgress) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

function showProgressUpload(url, data = {}, getProgress) {
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: function (progressEvent) {
        //属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
        //如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
        getProgress(progressEvent);
      },
      data,
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export default {
  post: post,
  get: get,
  uploadFile: uploadFile,
  showProgressUpload: showProgressUpload,
};
