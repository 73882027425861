import Home from '../../views/Home.vue';
export default {
  path: '/set',
  name: 'set',
  component: Home,
  redirect: '/set/shopSetup',
  meta: {
    name: '设置',
    title: '设置管理',
    rules: 1,
  },
  children: [
    {
      path: '/set/shopSetup',
      name: 'set-shopSetup',
      component: () => import('@/views/set/shopSetup/index.vue'),
      meta: {
        name: '店铺设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/userCenter',
      name: 'set-userCenter',
      meta: {
        name: '个人中心设置',
        rules: 1,
        form: 1,
      },
      component: () => import('@/views/addPages/pages/userCenter.vue'),
    },
    {
      path: '/set/sourceCenter',
      name: 'set-sourceCenter',
      meta: {
        name: '素材中心',
        rules: 1,
        form: 1,
      },
      component: () => import('@/views/set/sourceCenter/index00.vue'),
    },
    {
      path: '/set/AddCloudDisk',
      name: 'set-AddCloudDisk',
      meta: {
        name: '素材中心',
        rules: 0,
        relation: 'set-sourceCenter',
      },
      component: () => import('@/views/set/sourceCenter/AddCloudDisk.vue'),
    },
    {
      path: '/set/setOrder',
      component: () => import('@/views/set/SetOrder'),
      name: 'set-setOrder',
      meta: {
        name: '设置订单',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/message',
      name: 'set-message',
      component: () => import('@/views/set/Message'),
      meta: {
        name: '短信配置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/address',
      name: 'set-address',
      component: () => import('@/views/set/Address'),
      meta: {
        name: '退货地址配置',
        rules: 1,
      },
    },
    {
      path: '/set/businessTarget',
      name: 'set-businessTarget',
      component: () => import('@/views/set/BusinessTarget'),
      meta: {
        name: '营业目标',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/ServiceList',
      name: 'set-ServiceList',
      component: () => import('@/views/set/ServiceList'),
      meta: {
        name: '客服列表',
        rules: 1,
      },
    },
    {
      path: '/set/wechatCustomer',
      name: 'set-wechatCustomer',
      component: () => import('@/views/set/wechatCustomer'),
      meta: {
        name: '微信客服',
        rules: 1,
      },
    },
    {
      path: '/set/printSheet',
      name: 'set-printSheet',
      component: () => import('@/views/set/printSheet'),
      meta: {
        name: '收银账号',
        rules: 1,
      },
    },
    {
      path: '/set/Cashier',
      name: 'set-Cashier',
      component: () => import('@/views/set/Cashier.vue'),
      meta: {
        name: '收银台配置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/freightList',
      name: 'set-freightList',
      component: () => import('@/views/set/Freightlist'),
      meta: {
        name: '运费设置',
        rules: 1,
      },
    },
    {
      path: '/set/freight',
      name: 'set-freight',
      component: () => import('@/views/set/Freight'),
      meta: {
        name: '添加运费',
        relation: 'set-freightList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/set/freightEdit',
      name: 'set-freightEdit',
      component: () => import('@/views/set/Freight'),
      meta: {
        name: '编辑运费',
        relation: 'set-freightList',
        rules: 0,
        form: 1,
      },
    },
    {
      path: '/set/deposit',
      name: 'set-deposit',
      component: () => import('@/views/set/Deposit'),
      meta: {
        name: '押金设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/rightSet',
      name: 'set-rightSet',
      component: () => import('@/views/set/RightSet'),
      meta: {
        name: '权益设置',
        rules: 1,
      },
    },
    {
      path: '/set/serviceAgreement',
      name: 'set-serviceAgreement',
      component: () => import('@/views/set/ServiceAgreement'),
      meta: {
        name: '服务协议',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/settlement',
      name: 'set-settlement',
      component: () => import('@/beauty/settlement'),
      meta: {
        name: '结算设置',
        rules: 1,
        form: 1,
      },
    },
    //repair
    {
      path: '/repair/set/order',
      component: () => import('@/repair/set/order'),
      name: 'repair-set-order',
      meta: {
        name: '订单设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/repair/set/feedback',
      component: () => import('@/repair/set/feedback'),
      name: 'repair-set-feedback',
      meta: {
        name: '意见反馈',
        rules: 1,
      },
    },
    {
      path: '/repair/set/kf',
      component: () => import('@/repair/set/kf'),
      name: 'repair-set-kf',
      meta: {
        name: '客服电话',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/repair/set/appVersions',
      component: () => import('@/repair/set/appVersions'),
      name: 'repair-set-appVersions',
      meta: {
        name: 'APP版本',
        rules: 1,
      },
    },
    {
      path: '/repair/set/logs',
      component: () => import('@/repair/set/logs'),
      name: 'repair-set-logs',
      meta: {
        name: '操作日志',
        rules: 1,
      },
    },
    //餐饮新版本
    {
      path: '/set/send',
      component: () => import('@/views/set/send'),
      name: 'set-send',
      meta: {
        name: '配送设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/set/deliveryCharge',
      component: () => import('@/views/set/deliveryCharge'),
      name: 'set-deliveryCharge',
      meta: {
        name: '配送费收支',
        rules: 1,
      },
    },
  ],
};
