/*
 * @Author: lsw lsw_0524@163.com
 * @Date: 2023-11-01 14:38:14
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-01-06 13:46:19
 * @FilePath: \yiyun_project\src\router\modules\finance.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Home from '../../views/Home.vue';
export default {
  path: '/finance',
  name: 'finance',
  component: Home,
  redirect: '/finance/rechargeConsumptionRecord',
  meta: {
    name: '财务',
    title: '财务管理',
    rules: 1,
  },
  children: [
    {
      path: '/finance/rechargeConsumptionRecord',
      name: 'finance-rechargeConsumptionRecord',
      component: () => import('@/views/finance/RechargeConsumptionRecord'),
      meta: {
        name: '充值消耗记录',
        rules: 1,
      },
    },
    {
      path: '/finance/serviceRevenue',
      name: 'finance-serviceRevenue',
      component: () => import('@/views/finance/serviceRevenue'),
      meta: {
        name: '服务收益',
        rules: 1,
      },
    },
    {
      path: '/finance/goodsRevenue',
      name: 'finance-goodsRevenue',
      component: () => import('@/views/finance/goodsRevenue'),
      meta: {
        name: '商品收益',
        rules: 1,
      },
    },
    //
    {
      path: '/finance/settlementIncome',
      name: 'finance-settlementIncome',
      component: () => import('@/views/finance/settlementIncome'),
      meta: {
        name: '入驻收益',
        rules: 1,
      },
    },
    {
      path: '/finance/advertisingRevenue',
      name: 'finance-advertisingRevenue',
      component: () => import('@/views/finance/advertisingRevenue'),
      meta: {
        name: '广告收益',
        rules: 1,
      },
    },
    {
      path: '/finance/forumRevenue',
      name: 'finance-forumRevenue',
      component: () => import('@/views/finance/forumRevenue'),
      meta: {
        name: '论坛收益',
        rules: 1,
      },
    },
    {
      path: '/finance/depositList',
      name: 'finance-depositList',
      component: () => import('@/views/finance/depositList'),
      meta: {
        name: '押金列表',
        rules: 1,
      },
    },
    {
      path: '/finance/depositOperation',
      name: 'finance-depositOperation',
      component: () => import('@/views/finance/depositOperation'),
      meta: {
        name: '押金申退',
        rules: 1,
      },
    },
    {
      path: '/finance/financeList',
      name: 'finance-financeList',
      component: () => import('@/views/finance/financeList'),
      meta: {
        name: '财务列表',
        rules: 1,
      },
    },
    {
      path: '/finance/withdrawalAccount',
      name: 'finance-withdrawalAccount',
      component: () => import('@/views/finance/withdrawalAccount'),
      meta: {
        name: '提现账号',
        rules: 1,
      },
    },
    {
      path: '/finance/memberAssets',
      name: 'finance-memberAssets',
      component: () => import('@/views/finance/MemberAssets'),
      meta: {
        name: '会员资产',
        rules: 1,
      },
    },
    {
      path: '/finance/integralDetail',
      name: 'finance-integralDetail',
      component: () => import('@/views/finance/IntegralDetail'),
      meta: {
        name: '积分明细',
        rules: 1,
      },
    },
    {
      path: '/finance/employeeSettlement',
      name: 'finance-employeeSettlement',
      component: () => import('@/views/finance/employeeSettlement'),
      meta: {
        name: '员工结算',
        rules: 1,
      },
    },
    {
      path: '/finance/O2OSettlementList',
      name: 'finance-O2OSettlementList',
      component: () => import('@/O2O/finance/settlementList'),
      meta: {
        name: '结算列表',
        rules: 1,
      },
    },
    {
      path: '/finance/O2OWithdrawalManagement',
      name: 'finance-O2OWithdrawalManagement',
      component: () => import('@/O2O/finance/withdrawalManagement'),
      meta: {
        name: '提现管理',
        rules: 1,
      },
    },
    {
      path: '/finance/O2OOfflinePayment',
      name: 'finance-O2OOfflinePayment',
      component: () => import('@/O2O/finance/offlinePayment'),
      meta: {
        name: '线下打款',
        rules: 1,
      },
    },
    //gateway
    {
      path: '/finance/gatewayPlatformRevenue',
      name: 'finance-gatewayPlatformRevenue',
      component: () => import('@/gateway/finance/platformRevenue'),
      meta: {
        name: '平台收益',
        rules: 1,
      },
    },
    {
      path: '/finance/gatewayWithdrawalList',
      name: 'finance-gatewayWithdrawalList',
      component: () => import('@/gateway/finance/withdrawalList'),
      meta: {
        name: '提现列表',
        rules: 1,
      },
    },
    {
      path: '/finance/gatewayMemberAssets',
      name: 'finance-gatewayMemberAssets',
      component: () => import('@/gateway/finance/memberAssets'),
      meta: {
        name: '会员资产',
        rules: 1,
      },
    },
    //repair
    {
      path: '/repair/finance/depositOrder',
      name: 'repair-finance-depositOrder',
      component: () => import('@/repair/finance/depositOrder'),
      meta: {
        name: '保证金订单',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/depositRefund',
      name: 'repair-finance-depositRefund',
      component: () => import('@/repair/finance/depositRefund'),
      meta: {
        name: '保证金申退',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/details',
      name: 'repair-finance-details',
      component: () => import('@/repair/finance/details'),
      meta: {
        name: '资产明细',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/serveIncome',
      name: 'repair-finance-serveIncome',
      component: () => import('@/repair/finance/serveIncome'),
      meta: {
        name: '服务收益',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/goodsIncome',
      name: 'repair-finance-goodsIncome',
      component: () => import('@/repair/finance/goodsIncome'),
      meta: {
        name: '商品收益',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/artisanWithdraw',
      name: 'repair-finance-artisanWithdraw',
      component: () => import('@/repair/finance/artisanWithdraw'),
      meta: {
        name: '技工提现',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/storeWithdraw',
      name: 'repair-finance-storeWithdraw',
      component: () => import('@/repair/finance/storeWithdraw'),
      meta: {
        name: '商家提现',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/storeWithdrawDetail',
      name: 'repair-finance-storeWithdrawDetail',
      component: () => import('@/repair/finance/storeWithdrawDetail'),
      meta: {
        name: '商家提现',
        relation: 'repair-finance-storeWithdraw',
        rules: 0,
      },
    },
    {
      path: '/repair/finance/accounts',
      name: 'repair-finance-accounts',
      component: () => import('@/repair/finance/accounts'),
      meta: {
        name: '物业对账',
        rules: 1,
      },
    },
    {
      path: '/repair/finance/accountsDetail',
      name: 'repair-finance-accountsDetail',
      component: () => import('@/repair/finance/accountsDetail'),
      meta: {
        name: '订单详情',
        relation: 'repair-finance-accounts',
        rules: 0,
      },
    },
    {
      path: '/finance/reflectAudit',
      name: 'finance-reflectAudit',
      component: () => import('@/views/store/ReflectAudit'),
      meta: {
        name: '商城结算审核',
        rules: 1,
      },
    },
    {
      path: '/finance/Withdrawalrecord',
      name: 'finance-Withdrawalrecord',
      component: () => import('@/views/store/Withdrawalrecord'),
      meta: {
        name: '商城结算审核',
        relation: 'finance-reflectAudit',
        rules: 0,
      },
    },
    {
      path: '/finance/reflectAuditEnterprise',
      name: 'finance-reflectAuditEnterprise',
      component: () => import('@/views/store/ReflectAuditEnterprise'),
      meta: {
        name: '商家货款提现审核',
        rules: 1,
      },
    },
    {
      path: '/finance/WithdrawalrecordEnterprise',
      name: 'store-WithdrawalrecordEnterprise',
      component: () => import('@/views/store/WithdrawalrecordEnterprise'),
      meta: {
        name: '商家货款提现审核',
        relation: 'finance-reflectAuditEnterprise',
        rules: 0,
      },
    },
    {
      path: '/finance/gatherTweeters/withdrawalAudit',
      name: 'finance-gatherTweeters-withdrawalAudit',
      component: () => import('@/views/extension/gatherTweeters/withdrawalAudit'),
      meta: {
        name: '佣金提现审核',
        rules: 1,
      },
    },
    {
      path: '/finance/managersExamine',
      name: 'finance-managersExamine',
      component: () => import('@/views/finance/managersExamine'),
      meta: {
        name: '主理人结算列表',
        rules: 1,
      },
    },
    {
      path: '/finance/revenueOrdersAll',
      name: 'finance-revenueOrdersAll',
      component: () => import('@/views/extension/gatherTweeters/revenueOrdersAll'),
      meta: {
        name: '对账订单',
        rules: 1,
      },
    },
    {
      path: '/finance/downCenter',
      name: 'finance-downCenter',
      component: () => import('@/views/extension/gatherTweeters/downCenter'),
      meta: {
        name: '导出文件',
        rules: 1,
      },
    },
  ],
};
