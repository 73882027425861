/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-20 13:36:20
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-03-12 15:46:02
 * @FilePath: /yiyun_project/src/router/modules/shortVideo.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Home from '@/views/Home.vue';
export default {
  path: '/shortVideo',
  name: 'shortVideo',
  component: Home,
  redirect: '/shortVideo/shortVideoList',
  meta: {
    name: '短剧',
    title: '短剧管理',
    rules: 1,
  },
  children: [
    {
      path: '/shortVideo/shortVideoList',
      name: 'shortVideo-shortVideoList',
      component: () => import('@/views/shortVideo/shortVideoList'),
      meta: {
        name: '短剧列表',
        rules: 1,
      },
    },
    {
      path: '/shortVideo/addShortVideo',
      name: 'shortVideo-addShortVideo',
      component: () => import('@/views/shortVideo/addShortVideo'),
      meta: {
        name: '短剧管理',
        form: 1,
        relation: 'shortVideo-shortVideoList',
        rules: 0,
      },
    },
    {
      path: '/shortVideo/shortVideoCate',
      name: 'shortVideo-shortVideoCate',
      component: () => import('@/views/shortVideo/shortVideoCate'),
      meta: {
        name: '短剧分类',
        rules: 1,
      },
    },
    {
      path: '/shortVideo/basicSet',
      name: 'shortVideo-basicSet',
      component: () => import('@/views/shortVideo/basicSet'),
      meta: {
        name: '基础设置',
        rules: 1,
        form: 1,
      },
    },
    {
      path: '/shortVideo/cardKey',
      name: 'shortVideo-cardKey',
      component: () => import('@/views/shortVideo/cardKey'),
      meta: {
        name: '卡密管理',
        rules: 1,
      },
    },
    {
      path: '/shortVideo/taskManage',
      name: 'shortVideo-taskManage',
      component: () => import('@/views/shortVideo/taskManage'),
      meta: {
        name: '任务管理',
        rules: 1,
      },
    },
    {
      path: '/shortVideo/packageManage',
      name: 'shortVideo-packageManage',
      component: () => import('@/views/shortVideo/packageManag'),
      meta: {
        name: '套餐管理',
        rules: 1,
      },
    },
    {
      path: '/shortVideo/marketingManagement',
      name: 'shortVideo-marketingManagement',
      redirect: '/shortVideo/marketingManagement/vipCard',
      component: () => import('@/views/shortVideo/marketingManagement/index'),
      meta: {
        name: '营销管理',
        rules: 1,
        isOpenThirdMenu: 0,
        isShowThirdMemu: 1,
      },
      children: [
        {
          path: '/shortVideo/marketingManagement/vipCard',
          name: 'shortVideo-marketingManagement-vipCard',
          component: () => import('@/views/shortVideo/marketingManagement/vipCard'),
          meta: {
            name: 'vip管理',
            rules: 1,
          },
        },
        {
          path: '/shortVideo/marketingManagement/vipOrder',
          name: 'shortVideo-marketingManagement-vipOrder',
          component: () => import('@/views/shortVideo/marketingManagement/vipOrder'),
          meta: {
            name: 'vip订单',
            rules: 1,
          },
        },
      ],
    },
  ],
};
