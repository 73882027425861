import Home from '../../views/Home.vue';
export default {
    path: '/category',
    name: 'category',
    component: Home,
    redirect: '/category/categoryList',
    meta: {
        name: '品类',
        title: '品类管理',
        rules: 1,
    },
    children: [
        {
            path: '/category/categoryList',
            name: 'category-categoryList',
            component: () => import('@/O2O/category/categoryList'),
            meta: {
                name: '品类列表',
                rules: 1,
            },
        },
        {
            path: '/category/addCategory',
            name: 'category-addCategory',
            component: () => import('@/O2O/category/addCategory'),
            meta: {
                name: '添加品类',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/category/editCategory',
            name: 'categorye-ditCategory',
            component: () => import('@/O2O/category/addCategory'),
            meta: {
                name: '编辑品类',
                rules: 0,
                form: 1,
                relation: 'category-categoryList',
            },
        },
    ],
}