import store from '../store';
import _axios from './axios';
import _api from './api';
const modulesFiles = require.context('../store/modules', true, /\.js$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  modules.push(moduleName);
  return modules;
}, []);
/**
 * 转数字
 */
export function turnNum(val) {
  return val ? 1 : 0;
}
/**
 * 转布尔值
 */
export function turnBle(val) {
  return Number(val) ? !0 : !1;
}

/**
 *
 * @param name 要清除store模块的名称
 */
export function resetVuex(name) {
  let moduleName = name || modules;
  moduleName.map(item => store.commit(`${item}/resetState`));
}

export function matchDataAssign(val, data) {
  for (let i in data) {
    for (let y in val) {
      if (y == i) val[y] = data[i];
    }
  }
  return val;
}

export function getfilesize(size) {
  if (!size) return '';
  var num = 1024.0; //byte
  if (size < num) return size + 'B';
  if (size < Math.pow(num, 2)) return (size / num).toFixed(2) + 'K'; //kb
  if (size < Math.pow(num, 3)) return (size / Math.pow(num, 2)).toFixed(2) + 'M'; //M
  if (size < Math.pow(num, 4)) return (size / Math.pow(num, 3)).toFixed(2) + 'G'; //G
  return (size / Math.pow(num, 4)).toFixed(2) + 'T'; //T
}

// 截取视频第一帧 url传base64位
export function getVideoBase64(url) {
  return new Promise(function (resolve, reject) {
    let dataURL = '';
    let video = document.createElement('video');
    video.setAttribute('src', url);
    video.setAttribute('width', 400);
    video.setAttribute('height', 240);
    video.setAttribute('preload', 'auto');
    video.addEventListener('loadeddata', function () {
      let canvas = document.createElement('canvas'),
        width = video.width, //canvas的尺寸和图片一样
        height = video.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(video, 0, 0, width, height); //绘制canvas
      dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
      resolve(dataURL);
    });
  });
}
// 将视频转成base64进制的
export function changeVideoBase64(file, callback = () => {}) {
  var reader = new FileReader();
  var rs = reader.readAsDataURL(file);
  reader.onload = e => {
    var videoSrc = e.target.result;
    callback(videoSrc);
  };
}

export function getSourceWidthHeight(url, callback = () => {}) {
  let img = new Image();
  img.src = url;
  return new Promise(resolve => {
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
  });
}

export function fileChangeImage(file) {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => {
      // 图片base64化
      const base64Str = e.target.result;
      resolve(base64Str);
    };
  });
}

export function getSelected() {
  if (window.getSelection) {
    return window.getSelection().toString();
  } else if (document.getSelection) {
    return document.getSelection().toString();
  } else {
    var selection = document.selection && document.selection.createRange();
    if (selection.text) {
      return selection.text.toString();
    }
    return '';
  }
}

// 验证附件的数据合法性
export function checkAttachments(arr) {
  let attachmentsFlag = true;
  // 验证附件参数是否合法
  let errorTxt = null;
  let errIndex = null;
  for (let i = 0; i < arr.length; i++) {
    errIndex = i;
    let item = arr[i];
    if (item.msgtype === 'image') {
      if (!item.image.media_id) {
        errorTxt = '图片参数错误 请重新上传';
        attachmentsFlag = false;
        break;
      }
      if (!item.image.pic_url) {
        errorTxt = '请上传图片';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'video') {
      if (!item.video.media_id) {
        errorTxt = '视频参数错误 请重新上传';
        attachmentsFlag = false;
        break;
      }
      if (!item.video.path) {
        errorTxt = '请上传视频';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'link') {
      if (!item.link.title) {
        errorTxt = '请填写图文标题';
        attachmentsFlag = false;
        break;
      }
      if (!item.link.url) {
        errorTxt = '请填写图文链接';
        attachmentsFlag = false;
        break;
      }
      if (!item.link.desc) {
        errorTxt = '请填写图文秒速';
        attachmentsFlag = false;
        break;
      }
      if (!item.link.picurl) {
        errorTxt = '请填写图文封面';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'miniprogram') {
      if (!item.miniprogram.appid) {
        errorTxt = '请填写小程序APPID';
        attachmentsFlag = false;
        break;
      }
      if (!item.miniprogram.page) {
        errorTxt = '请填写小程序page路径';
        attachmentsFlag = false;
        break;
      }
      if (!item.miniprogram.title) {
        errorTxt = '请填写小程序标题';
        attachmentsFlag = false;
        break;
      }
      if (!item.miniprogram.pic_media_id) {
        errorTxt = '请填写小程序消息封面';
        attachmentsFlag = false;
        break;
      }
    }
    if (item.msgtype === 'file') {
      if (!item.file.media_id) {
        errorTxt = '文件参数错误 请重新上传';
        attachmentsFlag = false;
        break;
      }
    }
  }
  return { attachmentsFlag, errorTxt, errIndex };
}

export const identity_type_hash = {
  1: { name: '代理商', table_name: 'f_name', table_mobile: 'f_mobile' },
  2: { name: '商家', table_name: 's_name', table_mobile: 's_mobile' },
  3: { name: '合伙人', table_name: 'r_name', table_mobile: 'r_mobile' },
  4: { name: '员工' },
  5: { name: '员工' },
  6: { name: '商家', table_name: 's_name', table_mobile: 's_mobile' },
  7: { name: '圈主', table_name: 'c_name', table_mobile: 'c_mobile' },
  8: { name: '头号玩家', table_name: 'v_name', table_mobile: 'v_mobile' },
  9: { name: '主理人', table_name: 'h_name', table_mobile: 'h_mobile' },
  10: { name: '组局人', table_name: 'u_nickname', table_mobile: 'u_mobile' },
  11: { name: '分销员', table_name: 'd_nickname', table_mobile: 'd_mobile' },
};

export const dataURItoBlob = base64Data => {
  let byteString;
  if (base64Data.split(',')[0].indexOf('base64') >= 0) byteString = atob(base64Data.split(',')[1]);
  else byteString = unescape(base64Data.split(',')[1]);
  let mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
  let ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

// mode => 1-裁前半张 2-裁后半张
export const dealAtals = (data, mode) => {
  return new Promise(resolve => {
    const canvas_dom = document.createElement('canvas');
    canvas_dom.width = data.width / 2;
    canvas_dom.height = data.height;
    const ctx = canvas_dom.getContext('2d');
    ctx.beginPath();
    let img = new Image();
    img.src = data.path;
    img.onload = function () {
      if (mode == 1) ctx.drawImage(img, 0, 0, data.width / 2, data.height, 0, 0, data.width / 2, data.height);
      if (mode == 2) ctx.drawImage(img, data.width / 2, 0, data.width / 2, data.height, 0, 0, data.width / 2, data.height);
      let base64Data = canvas_dom.toDataURL('image/jpeg', 1.0);
      let blob = dataURItoBlob(base64Data);
      let from = new FormData();
      from.append('files', blob, mode == 1 ? `front_${data.name}` : `back_${data.name}`);
      from.append('wide', data.width / 2);
      from.append('height', data.height);
      _axios.uploadFile(_api.user.addFiles, from).then(res => {
        if (res.code == 0) {
          resolve(res.result.path);
        }
      });
    };
  });
};
