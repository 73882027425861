import Home from '../../views/Home.vue';
export default {
    path: '/reserve',
    name: 'reserve',
    component: Home,
    redirect: '/reserve/reserveList',
    meta: {
        name: '预约',
        title: '预约管理',
        rules: 1,
    },
    children: [
        {
            path: '/reserve/reserveList',
            name: 'reserve-reserveList',
            component: () => import('@/beauty/reserve/reserveList'),
            meta: {
                name: '预约列表',
                rules: 1,
            },
        },
        {
            path: '/reserve/reserveDetail',
            name: 'reserve-reserveDetail',
            component: () => import('@/beauty/reserve/reserveDetail'),
            meta: {
                name: '预约详情',
                relation: 'reserve-reserveList',
                rules: 0,
            },
        },
        {
            path: '/reserve/reserveBoard',
            name: 'reserve-reserveBoard',
            component: () => import('@/beauty/reserve/reserveBoard'),
            meta: {
                name: '预约看板',
                rules: 1,
            },
        },
        {
            path: '/reserve/reserveSet',
            name: 'reserve-reserveSet',
            component: () => import('@/beauty/reserve/reserveSet'),
            meta: {
                name: '预约设置',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/reserve/diagnosisList',
            name: 'reserve-diagnosisList',
            component: () => import('@/beauty/reserve/diagnosisList'),
            meta: {
                name: '诊断列表',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/reserve/diagnosisSet',
            name: 'reserve-diagnosisSet',
            component: () => import('@/beauty/reserve/diagnosisSet'),
            meta: {
                name: '诊断设置',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/reserve/diagnosisAdd',
            name: 'reserve-diagnosisAdd',
            component: () => import('@/beauty/reserve/diagnosisForm'),
            meta: {
                name: '添加诊断',
                rules: 0,
                form: 1,
                relation: 'reserve-reserveList',
            },
        },
        {
            path: '/reserve/diagnosisEdit',
            name: 'reserve-diagnosisEdit',
            component: () => import('@/beauty/reserve/diagnosisForm'),
            meta: {
                name: '编辑诊断',
                rules: 0,
                form: 1,
                relation: 'reserve-reserveList',
            },
        },
    ],
}