<template>
  <el-main>
    <div class="logo"><img src="../assets/t_logo.png" alt="" /></div>
    <div class="login-box">
      <form @submit="loginSubmit" class="login" v-if="status == 0">
        <div class="title">
          <p>欢迎登陆</p>
          <p>Welcome to our website</p>
        </div>
        <div class="form">
          <div class="login-type">
            <div class="password" :style="{ 'font-weight': loginType == 0 ? 'bold' : '' }" @click="selectLoginType(0)">密码登陆</div>
            <div class="code" :style="{ 'font-weight': loginType == 1 ? 'bold' : '' }" @click="selectLoginType(1)">验证码登陆</div>
            <div class="line" :style="{ left: loginType == 0 ? '15px' : '190px' }"></div>
          </div>
          <div class="password-input" v-if="loginType == 0">
            <div class="account">
              <img src="../assets/image/account-icon.png" alt="" />
              <div>
                <input type="text" v-model="loginForm.account" name="account" placeholder="请输入账号" />
              </div>
            </div>
            <div class="code">
              <img src="../assets/image/code-icon.png" alt="" />
              <div>
                <input type="password" v-model="loginForm.password" name="password" placeholder="请输入密码" />
                <input type="password" v-model="loginForm.password" name="password" placeholder="请输入密码" autocomplete="new-password" style="display: none" />
              </div>
            </div>
            <div class="forget-password">
              <div class="left">
                <el-checkbox v-model="is_remember" @change="getremember"></el-checkbox>
                <span>记住账号密码</span>
              </div>
              <div class="right" @click="showStatus(2)">
                <span>忘记密码？</span>
              </div>
            </div>
          </div>
          <div class="code-input" v-else>
            <div class="phone">
              <img src="../assets/image/account-icon.png" alt="" />
              <div>
                <input v-model="loginForm.account" placeholder="请输入手机号" autocomplete="off" />
              </div>
            </div>
            <div class="code">
              <img src="../assets/image/code-icon.png" alt="" />
              <div>
                <input type="text" v-model="loginForm.code" placeholder="请输入验证码" />
                <input type="text" v-model="loginForm.code" placeholder="请输入验证码" autocomplete="new-password" style="display: none" />
                <p @click="getCode">
                  {{ codeTime > 0 ? codeTime : '获取验证码' }}
                </p>
              </div>
            </div>
          </div>
          <div class="login-btn">
            <button type="submit">登录</button>
            <p @click="showStatus(1)">立即注册</p>
          </div>
        </div>
      </form>
      <div class="register" v-else-if="status == 1">
        <div class="register-box">
          <div class="title">
            <p>注册新账号</p>
            <p>注册即表示同意《软件注册协议》《隐私权政策》</p>
          </div>
          <div class="code-input">
            <div class="phone">
              <img src="../assets/image/account-icon.png" alt="" />
              <div>
                <input v-model="registerForm.phone" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="code" style="margin-bottom: 20px">
              <img src="../assets/image/code-icon.png" alt="" />
              <div>
                <input v-model="registerForm.code" placeholder="请输入验证码" />
                <p @click="getCode">
                  {{ codeTime > 0 ? codeTime : '获取验证码' }}
                </p>
              </div>
            </div>
            <div class="phone">
              <img src="../assets/image/pass-icon.png" alt="" />
              <div>
                <input type="password" v-model="registerForm.password" placeholder="设置8-16位含英文和数字密码" />
                <input type="password" v-model="registerForm.password" placeholder="设置8-16位含英文和数字密码" autocomplete="new-password" style="display: none" />
              </div>
            </div>
            <div class="phone">
              <img src="../assets/image/pass-icon.png" alt="" />
              <div>
                <input type="password" v-model="registerForm.password2" placeholder="请再次输入密码" />
                <input type="password" v-model="registerForm.password2" placeholder="请再次输入密码" autocomplete="new-password" style="display: none" />
              </div>
            </div>
          </div>
          <div class="login-btn">
            <button @click="registerSubmit">注册</button>
            <p @click="showStatus(0)">去登录</p>
          </div>
        </div>
      </div>
      <div class="retrieve" v-else>
        <div class="retrieve-box">
          <div class="title">
            <p>找回密码</p>
            <p>可通过手机号验证设置新密码</p>
          </div>
          <div class="code-input">
            <div class="phone">
              <img src="../assets/image/account-icon.png" alt="" />
              <div>
                <input v-model="retrieveForm.phone" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="code">
              <img src="../assets/image/code-icon.png" alt="" />
              <div>
                <input type="text" v-model="retrieveForm.code" placeholder="请输入验证码" />
                <p @click="getCode">
                  {{ codeTime > 0 ? codeTime : '获取验证码' }}
                </p>
              </div>
            </div>
            <div class="phone">
              <img src="../assets/image/pass-icon.png" alt="" />
              <div>
                <input type="password" v-model="retrieveForm.password" placeholder="设置新密码" />
                <input type="password" v-model="retrieveForm.password" placeholder="设置新密码" autocomplete="new-password" style="display: none" />
              </div>
            </div>
          </div>
          <div class="login-btn">
            <button @click="retrieveSubmit">确认修改</button>
            <p @click="showStatus(0)">去登录</p>
          </div>
        </div>
      </div>
    </div>
    <div class="slide-verify-dialog" v-show="dialogVisible">
      <div class="slide-verify-box">
        <div class="slide-verify-title">
          <span>请完成完全验证</span>
          <i class="el-icon-close" @click="dialogVisible = !1"></i>
        </div>
        <slide-verify ref="slideblock" @again="onAgain" :imgs="imgs" @fulfilled="onFulfilled" @success="onSuccess" @fail="onFail" :accuracy="accuracy" :slider-text="slider_text"></slide-verify>
      </div>
    </div>
    <div class="address">
      <p>©2015-2023 power by eYun Pay</p>
    </div>
  </el-main>
</template>
<script>
let Base64 = require('js-base64').Base64;
import img1 from '@/assets/image/verify/img1.jpg';
import img2 from '@/assets/image/verify/img2.jpg';
import img3 from '@/assets/image/verify/img3.jpg';
export default {
  data() {
    return {
      imgs: [img1, img2, img3],
      checkCode: '', //校验码
      isVerifySuccess: !1, //校验码是否校验成功
      checkCodeImg: '', //校验码图片
      dialogVisible: !1,
      codeTime: 0, //验证码等待时间
      status: 0, //切换状态
      loginType: 0, //登录类型
      is_remember: !1,
      loginForm: {
        account: '',
        password: '',
        code: '',
      },
      registerForm: {
        phone: '',
        code: '',
        name: '',
        password: '',
        password2: '',
      },
      retrieveForm: {
        phone: '',
        code: '',
        password: '',
      },
      slider_text: '向右拖动滑块填充拼图',
    };
  },
  created() {
    if (this.$cookies.get('token')) this.$router.go(1);
    if (localStorage.getItem('password')) {
      this.loginForm.account = localStorage.getItem('account');
      this.loginForm.password = Base64.decode(localStorage.getItem('password'));
      this.is_remember = !0;
    }
  },
  methods: {
    onSuccess() {
      this.dialogVisible = !1;
      this.isVerifySuccess = !0;
      this.onAgain();
      this.getCode();
    },
    onFail() {
      console.log('验证不通过');
    },
    onFulfilled() {
      console.log('刷新成功啦！');
    },
    onAgain() {
      // 刷新
      this.$refs.slideblock.reset();
    },
    getremember(val) {
      if (val) {
        localStorage.setItem('account', this.loginForm.account);
        localStorage.setItem('password', Base64.encode(this.loginForm.password));
      } else {
        localStorage.removeItem('account');
        localStorage.removeItem('password');
      }
    },
    selectLoginType(i) {
      this.loginType = i;
      for (let i in this.loginForm) this.loginForm[i] = '';
    },
    showStatus(i) {
      this.status = i;
      for (let i in this.loginForm) this.loginForm[i] = '';
      for (let i in this.registerForm) this.registerForm[i] = '';
      for (let i in this.retrieveForm) this.retrieveForm[i] = '';
    },
    //登录提交
    loginSubmit(e = {}) {
      if (e.preventDefault) e.preventDefault();
      let that = this;
      let loginForm = that.loginForm;
      if (!that.loginType) {
        if (!loginForm.account) return that.$message.warning('请填写账号');
        if (!loginForm.password) return that.$message.warning('请填写密码');
        //账号密码登录
        that.$axios
          .post(that.$api.user.login, {
            u_account: loginForm.account,
            u_password: loginForm.password,
          })
          .then(res => {
            if (res.code == 0) {
              that.$message({
                message: '登录成功',
                type: 'success',
              });
              that.getremember(this.is_remember);
              that.$cookies.set('token', res.result.token, 60 * 60 * 24);
              that.$router.push('/shop');
              localStorage.setItem('u_account', loginForm.account);
            } else {
              that.$message.error(res.msg);
            }
          });
      } else {
        //短信登录
        let isPhone = that.inspectPhone(loginForm.account);
        if (isPhone) {
          that.$axios
            .post(that.$api.user.codeLogin, {
              u_account: loginForm.account,
              code: loginForm.code,
            })
            .then(res => {
              if (res.code == 0) {
                that.$message({
                  message: '登录成功',
                  type: 'success',
                });
                that.$cookies.set('token', res.result.token, 60 * 60 * 24);
                that.$router.push('/shop/index').catch(() => {});
                localStorage.setItem('u_account', loginForm.account);
              } else {
                that.$message.error(res.msg);
              }
            });
        }
      }
    },
    //找回密码
    retrieveSubmit() {
      let retrieveForm = this.retrieveForm;
      let isPhone = this.inspectPhone(retrieveForm.phone);
      if (!retrieveForm.code) return this.$message.warning('请填验证码');
      if (!retrieveForm.password) return this.$message.warning('请填写密码');
      if (isPhone) {
        this.$axios
          .post(this.$api.user.forgetPwd, {
            u_account: retrieveForm.phone,
            code: retrieveForm.code,
            u_password: retrieveForm.password,
          })
          .then(res => {
            if (res.code == 0) {
              this.loginType = 0;
              this.loginForm.account = retrieveForm.phone;
              this.loginForm.password = retrieveForm.password;
              this.loginSubmit();
            } else {
              this.$message.error(res.msg);
            }
          });
      }
    },
    //注册提交
    registerSubmit() {
      let that = this;
      try {
        let registerForm = that.registerForm;
        if (!registerForm.code) throw '请填写验证码';
        if (!registerForm.phone) throw '请填写手机号';
        if (!registerForm.password) throw '请填写密码';
        if (!registerForm.password2) throw '请填写重复的密码';
        if (registerForm.password2 != registerForm.password) throw '密码与重复密码不匹配';
        this.$axios
          .post(that.$api.user.registerUser, {
            username: registerForm.phone,
            code: registerForm.code,
            u_password: registerForm.password,
            re_u_password: registerForm.password2,
          })
          .then(res => {
            if (res.code == 0) {
              this.loginType = 0;
              this.loginForm.account = registerForm.phone;
              this.loginForm.password = registerForm.password;
              this.loginSubmit();
            } else {
              that.$message.error(res.msg);
            }
          });
      } catch (e) {
        that.$message.warning(e);
      }
    },
    //获取验证码
    getCode() {
      let phone = null;
      if (this.status == 0) {
        phone = this.loginForm.account;
      } else if (this.status == 1) {
        phone = this.registerForm.phone;
      } else {
        phone = this.retrieveForm.phone;
      }
      let isPhone = this.inspectPhone(phone);
      if (isPhone && !this.codeTime) {
        if (this.isVerifySuccess) {
          this.sendCode(phone).then(res => {
            if (res.code == 0) {
              this.setCodeTime();
              this.$message({
                message: '短信发送成功',
                type: 'success',
              });
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.dialogVisible = !0;
        }
      }
    },
    //验证码计时
    setCodeTime() {
      this.codeTime = 60;
      this.timer = setInterval(() => {
        this.codeTime--;
        if (this.codeTime == 0) clearInterval(this.timer);
      }, 1000);
    },
    //检查手机号格式
    inspectPhone(val) {
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(val)) {
        this.$message.warning('请填写正确的手机号');
        return false;
      } else {
        return true;
      }
    },
    //发送验证码
    sendCode(val) {
      return new Promise((resolve, reject) => {
        this.$axios
          .post(this.$api.user.systemSendSms, {
            phone: val,
          })
          .then(res => {
            this.isVerifySuccess = !1;
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  height: 100%;
  width: 100%;
  background: url('~@/assets/image/login-back.png') no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .logo {
    width: 100%;
    padding-left: 50px;
    img {
      width: 200px;
      position: relative;
      top: 20px;
    }
  }

  .address {
    width: 100%;
    text-align: center;
    color: #999;
    font-size: 16px;
    // position: absolute;
    bottom: 60px;
  }

  .login-box {
    width: 520px;
    height: 550px;
    background: #ffffff;
    box-shadow: 0px 4px 24px 0px rgba(76, 100, 255, 0.2);
    border-radius: 8px;
    padding-top: 40px;

    .retrieve {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .register-box {
        width: 320px;
      }

      .title {
        text-align: center;

        p:nth-child(1) {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #4c64ff;
          margin-bottom: 10px;
        }

        p:nth-child(2) {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          opacity: 0.5;
        }
      }

      .code-input {
        margin-top: 60px;
        width: 100%;
        margin-bottom: 80px;

        .phone {
          margin-bottom: 20px;
        }

        .phone,
        .code {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          img {
            margin-right: 10px;
          }

          div {
            height: 40px;
            flex: 1;
            display: flex;

            input {
              border: none;
              height: 100%;
              flex: 1;
            }

            border-bottom: 2px solid #e6e6e6;

            p {
              cursor: pointer;
              width: 70px;
              text-align: center;
              height: 32px;
              background: #4c64ff;
              border-radius: 12px;
              font-size: 12px;
              color: #fff;
              line-height: 32px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .register {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .register-box {
        width: 320px;
      }

      .title {
        text-align: center;

        p:nth-child(1) {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #4c64ff;
          margin-bottom: 10px;
        }

        p:nth-child(2) {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          opacity: 0.5;
        }
      }

      .code-input {
        margin-top: 45px;
        width: 100%;
        margin-bottom: 11px;

        .phone {
          margin-bottom: 20px;
        }

        .phone,
        .code {
          display: flex;
          align-items: center;

          // margin-bottom: 20px;
          img {
            margin-right: 10px;
          }

          div {
            height: 40px;
            flex: 1;
            display: flex;

            input {
              border: none;
              height: 100%;
              flex: 1;
            }

            border-bottom: 2px solid #e6e6e6;

            p {
              cursor: pointer;
              width: 70px;
              text-align: center;
              height: 32px;
              background: #4c64ff;
              border-radius: 12px;
              font-size: 12px;
              color: #fff;
              line-height: 32px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }

    .login {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        text-align: center;

        p:nth-child(1) {
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #4c64ff;
          margin-bottom: 5px;
        }

        p:nth-child(2) {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          opacity: 0.5;
        }
      }

      .form {
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .login-type {
        display: flex;
        align-items: flex-start;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 45px;
        height: 35px;
        position: relative;

        .password {
          margin-right: 97px;
        }

        .password,
        .code {
          cursor: pointer;
        }

        .line {
          position: absolute;
          left: 15px;
          bottom: 0;
          width: 40px;
          height: 4px;
          background: #4c64ff;
          border-radius: 2px;
          transition: left 0.3s;
        }
      }

      .code-input {
        margin-top: 50px;
        width: 100%;
        margin-bottom: 41px;

        .phone {
          margin-bottom: 20px;
        }

        .phone,
        .code {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          div {
            height: 40px;
            flex: 1;
            display: flex;

            input {
              border: none;
              height: 100%;
              flex: 1;
            }

            border-bottom: 2px solid #e6e6e6;

            p {
              cursor: pointer;
              width: 70px;
              text-align: center;
              height: 32px;
              background: #4c64ff;
              border-radius: 12px;
              font-size: 12px;
              color: #fff;
              line-height: 32px;
              margin-bottom: 5px;
            }
          }
        }
      }

      .password-input {
        margin-top: 50px;
        width: 100%;

        .account {
          margin-bottom: 20px;
        }

        .account,
        .code {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          div {
            height: 32px;
            flex: 1;

            input {
              border: none;
              height: 100%;
              width: 100%;
            }

            border-bottom: 2px solid #e6e6e6;
          }
        }

        .forget-password {
          margin-top: 40px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          padding-left: 5px;

          .left {
            display: flex;
            align-items: center;
            color: #333;

            .el-checkbox {
              margin-right: 8px;

              .el-checkbox__inner {
                width: 20px;
                height: 20px;
              }
            }
          }

          .right {
            color: #999;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.login-btn {
  margin-top: 35px;
  text-align: center;

  button {
    width: 320px;
    height: 56px;
    background: #4c64ff;
    box-shadow: 0px 4px 24px 0px rgba(51, 68, 184, 0.3);
    border-radius: 5px;
    border: 0;
    color: #fff;
    font-size: 20px;
    margin-bottom: 35px;
    line-height: 56px;
  }

  p {
    color: #4c64ff;
    font-size: 16px;
    cursor: pointer;
  }
}

.slide-verify-dialog {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  .slide-verify-box {
    background: #f5f5f5;
    padding: 20px 15px;

    .slide-verify-title {
      display: flex;
      font-size: 20px;
      margin-bottom: 20px;
      align-items: center;
      justify-content: space-between;

      i {
        cursor: pointer;
      }
    }
  }
}
</style>
