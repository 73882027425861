import Home from '../../views/Home.vue';
export default {
    path: '/region',
    name: 'region',
    component: Home,
    redirect: '/region/regionalAdministratorList',
    meta: {
        name: '区域',
        title: '区域管理',
        rules: 1,
    },
    children: [
        {
            path: '/region/regionalAdministratorList',
            name: 'region-regionalAdministratorList',
            component: () => import('@/O2O/region/regionalAdministratorList'),
            meta: {
                name: '区域管理员列表',
                rules: 1,
            },
        },
        {
            path: '/region/addAdministrators',
            name: 'region-addAdministrators',
            component: () => import('@/O2O/region/addAdministrators'),
            meta: {
                name: '添加管理员',
                rules: 1,
                form: 1,
            },
        },
        {
            path: '/region/editAdministrators',
            name: 'region-editAdministrators',
            component: () => import('@/O2O/region/addAdministrators'),
            meta: {
                name: '编辑管理员',
                rules: 0,
                form: 1,
                relation: 'region-addAdministrators',
            },
        },
    ],
}