/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-01-19 10:08:26
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-05-25 16:59:08
 * @FilePath: /yiyun_project/src/util/config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// https://digitalize.tongli3d.com
// https://digitizationstore.tongli3d.com
// digitalizesocket.tongli3d.com

// https://digitalize.szysqclub.com
// https://digitizationstore.szysqclub.com/login
// wss://digitalizesocket.szysqclub.com/wss?access_token=
export default {
  baseurl: 'https://digitalize.tongli3d.com', //接口地址
  storeurl: 'https://digitizationstore.tongli3d.com/login', //电商门店测试线地址
};

export const ServicePath = 'https://admincustomer.5g-gov.com/'; //客服地址

export const path = 'wss://digitalizesocket.tongli3d.com/wss?access_token='; //socket地址

export const imgUrlHead = 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/'; // 项目图片地址前缀

export const shortImage = 'https://eyunpay-club.oss-cn-guangzhou.aliyuncs.com/shortVideo/'; // 短剧后台显示图片地址

export const szysqImage = 'https://eyunpay-club.oss-cn-guangzhou.aliyuncs.com/clubMini/muisc/'; // 品质优选组件图片地址

export const szysq = 'https://eyunpay-club.oss-cn-guangzhou.aliyuncs.com/szysq/'; // 数智云商圈图片地址

export const eyun_sass_admin = 'https://eyunpay-club.oss-cn-guangzhou.aliyuncs.com/admin2.0/';
